const defaultData = {
  fontUrl:
    "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  collectionName: "Certificate of Completion",
  templateStyleID: "1q2w3e4r",
  backgroundImage:
    "https://res.cloudinary.com/dqqkokon9/image/upload/v1676099885/Certgo%20Certificate/n4n2cugtorjaoxhooza7.png",
  backgroundColor: "rgba(255, 255, 255, 1)",
  containerFontSize: 24,
  elements: [
    {
      name: "logo",
      url: "",
      top: 9,
      left: 50,
      width: 10,
      borderRadius: "50%",
      isLogo: true,
      transform: "translateX(-50%)",
      aspectRatio: "1 / 1",
      fontSize: "1em",
      fontFamily: "Roboto"
    }
  ],
  texts: [
    {
      name: "title",
      top: 24,
      left: 50,
      fontSize: 2,
      fontWeight: 600,
      width: 80,
      textTransform: "uppercase",
      fontFamily: "Roboto",
      color: "rgba(34, 34, 34, 1)",
      text: "Certificate of Completion",
      transform: "translateX(-50%)",
      textAlign: "center",
      fontStyle: "normal",
      textDecoration: "none"
    },
    {
      name: "name",
      top: 42,
      left: 50,
      fontSize: 2,
      fontWeight: 500,
      width: 80,
      fontFamily: "Merriweather",
      color: "rgba(34, 34, 34, 1)",
      isAttribute: true,
      text: "John Doe",
      attribute: "name",
      transform: "translateX(-50%)",
      textAlign: "center",
      fontStyle: "normal",
      textDecoration: "none",
      textTransform: "uppercase"
    },
    {
      name: "description",
      width: 68,
      top: 53, //? REAL
      left: 50, //? REAL
      fontSize: 1, //? REAL
      fontFamily: "Roboto", //? REAL
      fontWeight: 400,
      color: "rgba(34, 34, 34, 1)",
      text: "participated and completed the HNG i7 Internship and took part in building a live project",
      transform: "translateX(-50%)",
      textAlign: "center",
      fontStyle: "normal",
      textDecoration: "none",
      textTransform: "none"
    },
    {
      name: "date",
      top: 73.2,
      left: 10.85,
      fontSize: 1, //? REAL
      fontFamily: "Roboto", //? REAL
      fontWeight: 500,
      text: getCurrentData(),
      color: "rgba(34, 34, 34, 1)",
      textAlign: "center",
      transform: "none",
      width: 26,
      fontStyle: "normal",
      textDecoration: "none",
      textTransform: "none"
    },
    {
      name: "issuedBy",
      top: 73.2,
      left: 63.3,
      fontSize: 1, //? REAL
      fontFamily: "Roboto", //? REAL
      fontWeight: 500,
      color: "rgba(34, 34, 34, 1)",
      text: "Henzyd",
      textAlign: "center",
      transform: "none",
      width: 26,
      fontStyle: "normal",
      textDecoration: "none",
      textTransform: "none"
    }
  ]
};

export function getCurrentData() {
  const isoDate = new Date();
  const dateOnly = isoDate.toISOString().slice(0, 10);
  return dateOnly;
}

export const certificateTemplateColorData = [
  {
    id: 1,
    color: "rgba(255, 255, 255, 1)", //? White
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825369/Certgo%20Certificate/Template%201/ldvzavrzlij9lkj7hkpj.png"
  },
  {
    id: 2,
    color: "rgba(224, 249, 250, 1)", //? Blue
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825369/Certgo%20Certificate/Template%201/plmci4ny6fwgtbkn8yff.png"
  },
  {
    id: 3,
    color: "rgba(255, 221, 242, 1)", //? Pink
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825369/Certgo%20Certificate/Template%201/epakcoccddhmgynqsqrb.png"
  },
  {
    id: 4,
    color: "rgba(178, 255, 211, 1)", //? Green
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/dvvvgp5gzlgohyjhbyvc.png"
  },
  {
    id: 5,
    color: "rgba(226, 214, 250, 1)", //? Purple
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/kbokk8pswptx0gzro88i.png"
  },
  {
    id: 6,
    color: "rgba(255, 225, 206, 1)", //? Orange
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/blo9iy7qv2a0uiomwmgb.png"
  },
  {
    id: 7,
    color: "rgba(246, 191, 189, 1)", //? Red
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/m9iyot1k1cap0ucnjqqr.png"
  },
  {
    id: 8,
    color: "rgba(194, 194, 194, 1)", //? Gray
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/ocwe1dwmnfjduqlu9y3l.png"
  },
  {
    id: 9,
    color: "rgba(41, 41, 41, 1)", //? Black
    bgImageColor:
      "https://res.cloudinary.com/dqqkokon9/image/upload/v1676825368/Certgo%20Certificate/Template%201/erz0kh3vw8ms3zm2npab.png"
  }
];

export default defaultData;
