import React from "react";
import { InputLabel, OutlinedInput } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import styles from "./form-field.module.scss";

const FormField = ({ label, ...props }) => {
  return (
    <Field {...props}>
      {({ field }) => (
        <div id={styles["input-wrapper"]}>
          {label && (
            <div className={styles["label-wrapper"]}>
              <InputLabel htmlFor={props.name} className={styles["label"]}>
                {label}
                {/* {props.required && <span>*</span>} */}
              </InputLabel>
            </div>
          )}
          <OutlinedInput
            className={styles["input"]}
            {...field}
            {...props}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "11px 13.5px",
                borderColor: `${props.readOnly ? "transparent" : "#0071B9"}`
              },
              "& .Mui-focused": {
                borderColor: "#0071B9"
              }
            }}
          />
          <ErrorMessage
            name={props.name}
            className={styles[`error-message`]}
            component={"p"}
            data-testid={`${props["data-testid"]}-error`}
          />
        </div>
      )}
    </Field>
  );
};

export default FormField;
