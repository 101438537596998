import React, { createContext, useState, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
// import TemplateDummyData from "../test_index.json";
// import DefaultTemplateData from "../utils/defaultTemplateData";
import CertificateTemplatesAPI from "../api/certificateTemplate";
import { axiosFormData, axiosPrivate } from "../api/axios";

export const CertificateContext = createContext();

const CertificateProvider = ({ children }) => {
  const [certElement, setCertElement] = useState({
    logo: null,
    signature: null
  }); //? file object

  const [currentTemplate, setCurrentTemplate] = useState({});

  let changeCertificateStorageValues = localStorage.getItem(
    "changeCertificateValues"
  );
  changeCertificateStorageValues = changeCertificateStorageValues
    ? JSON.parse(changeCertificateStorageValues)
    : null;
  const [changeCertificateValues, setChangeCertificateValues] = useState(
    changeCertificateStorageValues || {
      elements: {},
      texts: {}
    }
  );
  const textInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const [dropdownDisplay, setDropdownDisplay] = useState(false);
  const [currentViewTemplate, setCurrentViewTemplate] = useState(1);
  const [activeText, setActiveText] = useState({});

  let certStorageStatus = localStorage.getItem("certificateStatus");
  certStorageStatus = certStorageStatus ? JSON.parse(certStorageStatus) : null;

  const queryClient = useQueryClient();
  /**
   * Array of certificate templates.
   * @type {CertificateTemplate[]}
   */
  const certificateTemplatesQuery = useQuery({
    queryKey: ["_certificateTemplates"],
    // queryFn: async () => {
    //   return Promise.resolve(TemplateDummyData);
    // },
    queryFn: CertificateTemplatesAPI.getCertificateTemplates,
    onError: error => {
      console.log(error);
    },
    onSuccess: data => {
      // console.log(data);

      if (certStorageStatus === "EDIT") {
        return;
      }

      let localStorageCurrentTemplate =
        localStorage.getItem("_currentTemplate");
      localStorageCurrentTemplate = localStorageCurrentTemplate
        ? JSON.parse(localStorageCurrentTemplate)
        : null;

      if (localStorageCurrentTemplate) {
        queryClient.setQueryData(["_currentTemplate"], {
          ...localStorageCurrentTemplate
        });
        setCurrentTemplate({ ...localStorageCurrentTemplate });
        return;
      }

      const templateData = structuredClone(data[0]);
      const signatureData = templateData.elements.find(
        item => item.name === "signature"
      );
      if (signatureData) {
        templateData.elements.splice(
          templateData.elements?.indexOf(signatureData),
          1
        );
      }

      queryClient.setQueryData(["_currentTemplate"], { ...templateData });
      localStorage.setItem(
        "_currentTemplate",
        JSON.stringify({
          ...templateData
        })
      );
      setCurrentTemplate({ ...templateData });
    },
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
    refetchOnWindowFocus: false
  });

  /**
   * Saves the current template data to the local storage.
   * @param {CertificateTemplate} tempData - The template data.
   */
  function saveToStorage(tempData) {
    localStorage.setItem(
      "_currentTemplate",
      JSON.stringify(tempData || currentTemplate)
    );

    localStorage.setItem(
      "changeCertificateValues",
      JSON.stringify(changeCertificateValues)
    );
  }

  /**
   * Updates the template data.
   * @param {string|null} text - The name of the text element to update.
   * @param {Object} [obj={ text: "this is working" }] - The updated properties of the text element.
   * @param {string|null} backgroundImage - The URL of the background image to update.
   * @param {string|null} element - The name of the element to update.
   * @param {Object} [logoObj={ url: "" }] - The updated properties of the logo element.
   */
  const updateTemplateData = (
    text,
    obj = { text: "this is working" },
    backgroundColor,
    element,
    logoObj = { url: "" }
  ) => {
    if (backgroundColor) {
      setCurrentTemplate(prev => ({
        ...prev,
        backgroundColor: backgroundColor
      }));
    } else if (element) {
      setCurrentTemplate(prev => ({
        ...prev,
        elements: prev.elements.map(item => {
          if (item.name === element) {
            return {
              ...item,
              ...logoObj
            };
          } else {
            return item;
          }
        })
      }));
    } else {
      setCurrentTemplate(prev => ({
        ...prev,
        texts: prev.texts.map(item => {
          if (item.name === text) {
            return {
              ...item,
              ...obj
            };
          } else {
            return item;
          }
        })
      }));
    }
  };

  /**
   * Handles the file upload -> Logo and signature only.
   * @param {HTMLInputElement} e - The file input event.
   * @param {string} [element="logo"] - The name of the element to update.
   */
  function fileUploadHandler(e, element = "logo") {
    const fileUploaded = e.target.files[0];
    if (!fileUploaded) return;

    if (element === "logo") {
      setCertElement(prev => ({ ...prev, logo: fileUploaded }));
    }
    if (element === "signature") {
      setCertElement(prev => ({ ...prev, signature: fileUploaded }));
    }

    updateTemplateData(null, null, null, element, {
      url: URL.createObjectURL(fileUploaded)
    });
  }

  //? NOTE: you need to refactor this code
  function addObjectToDataElements(name, object) {
    setCurrentTemplate(prev => {
      //? Check if any existing element has the same name
      const hasTitleSmall = prev.elements.some(
        element => element.name === name
      );

      //? If the name already exists, return the previous state without making any changes
      if (hasTitleSmall) {
        return prev;
      }

      //? Otherwise, add the new element object to the elements array
      return {
        ...prev,
        elements: [...prev.elements, object]
      };
    });
  }

  function addObjectToDataTexts(name, object) {
    setCurrentTemplate(prev => {
      //? Check if any existing text has the same name
      const hasTitleSmall = prev.texts.some(text => text.name === name);

      //? If the name already exists, return the previous state without making any changes
      if (hasTitleSmall) {
        return prev;
      }

      //? Otherwise, add the new text object to the texts array
      return {
        ...prev,
        texts: [...prev.texts, object]
      };
    });
  }

  function setAllDefualtHandler() {
    //? This function is used to set the state of all the texts and icon to their default state
    setCurrentTemplate({ ...currentTemplate });
    setCertElement({ logo: null, signature: null });
  }

  /**
   * Uploads the logo and signature to the S3 bucket.
   * @param {File} logo - The logo file.
   * @param {File} signature - The signature file.
   * @param {CertificateTemplate} tempData - The template data.
   */
  const uploadImgToS3Bucket = async (logo, signature, tempData) => {
    if ((!logo && !signature) || !tempData) return;

    const data = { ...tempData };

    /**
     * Sets the URL of the logo and signature element.
     * @param {('logo' | 'signature')} name - The name of the element.
     * @param {string} url - The URL of the element.
     */
    function setURL(name, url) {
      data.elements = data.elements.map(item => {
        if (item.name === name) {
          return {
            ...item,
            url
          };
        } else {
          return item;
        }
      });
    }

    try {
      if (logo) {
        const logoResponse = await axiosPrivate.postForm("/templates/logo", {
          logo
        });
        const logoURL = logoResponse.data.data;
        if (logoURL) {
          setURL("logo", logoURL);
        }
      }
      if (signature) {
        const signatureResponse = await axiosPrivate.postForm(
          "/templates/signature",
          {
            signature
          }
        );
        const signatureURL = signatureResponse.data.data;
        if (signatureURL) {
          setURL("signature", signatureURL);
        }
      }
    } catch (err) {
      console.log(err);
    }

    return data;
  };

  /**
   *
   * @param {('elements' | 'texts')} parentKey - The parent key of the object.
   * @param {string} childKey - The child key of the object. (name) of the element or text
   * @param {Object} valueObj - The updated properties of the element or text. (color, text, top, left, etc.)
   */
  function changeCertificateValuesHandler(parentKey, childKey, valueObj) {
    setChangeCertificateValues(prev => {
      return {
        ...prev,
        [parentKey]: {
          ...prev[parentKey],
          [childKey]: {
            ...prev[parentKey][childKey],
            ...valueObj
          }
        }
      };
    });
  }

  return (
    <CertificateContext.Provider
      value={{
        certificateTemplatesQuery,
        currentTemplate,
        setCurrentTemplate,
        certElement,
        setCertElement,
        fileUploadHandler,
        changeCertificateValues,
        setChangeCertificateValues,
        updateTemplateData,
        saveToStorage,
        dropdownDisplay,
        setDropdownDisplay,
        textInputRef,
        fileInputRef,
        logoInputRef,
        addObjectToDataElements,
        addObjectToDataTexts,
        currentViewTemplate,
        setCurrentViewTemplate,
        activeText,
        setActiveText,
        setAllDefualtHandler,
        uploadImgToS3Bucket,
        changeCertificateValuesHandler
      }}
    >
      {children}
    </CertificateContext.Provider>
  );
};

export default CertificateProvider;
