import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Radio, RadioGroup } from "@mui/material";
import { BsCheckCircleFill } from "react-icons/bs";
import { GrNext } from "react-icons/gr";
import styles from "./plan.module.scss";
import PlanDetails from "./plan-details";
import StarIcon from "../../../assets/svgs/Conatct-locaton-icon-star.svg";
import NewButton from "../../new-button";
import PlanData from "../../../utils/data/plan.json";

const Plan = ({ setClientSecret, setModalState }) => {
  const [chosenPlan, setChosenPlan] = useState({
    value: "standard",
    done: false
  });

  useEffect(() => {
    return () => {
      setChosenPlan({ value: "standard", done: false });
    };
  }, []);

  const standardPlan = PlanData.find(item => item.plan === "standard");
  const premiumPlan = PlanData.find(item => item.plan === "premium");

  return (
    <>
      {!chosenPlan.done ? (
        <div id={styles["plan-list"]}>
          <h4 className={styles["title"]}>Select a Plan</h4>
          <RadioGroup
            className={styles["radio-group"]}
            value={chosenPlan.value}
          >
            <div
              className={`${styles["radio-wrapper"]} ${styles["standard-radio"]}`}
              onClick={() => {
                setChosenPlan(prev => ({ ...prev, value: "standard" }));
              }}
            >
              <Radio value={"standard"} className={styles["radio"]} />
              <div>
                <div>
                  <p>Standard</p>
                  <span>
                    <p>Recommended</p>
                    <img src={StarIcon} alt="star icon" />
                  </span>
                  <h1>
                    {`$${standardPlan?.monthly?.price}`}
                    <span>/month</span>
                  </h1>
                </div>
                <div>
                  <h4>Everything in Basic +</h4>
                  <div>
                    {standardPlan?.features.map((text, index) => (
                      <div key={index}>
                        <BsCheckCircleFill size={16} />
                        <p>{text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles["radio-wrapper"]} ${styles["premium-radio"]}`}
              onClick={() => {
                setChosenPlan(prev => ({ ...prev, value: "premium" }));
              }}
            >
              <Radio value={"premium"} className={styles["radio"]} />
              <div>
                <div>
                  <p>Premium</p>
                  <span>Best for large organisations</span>
                  <h1>
                    {`$${premiumPlan?.monthly?.price}`}
                    <span>/month</span>
                  </h1>
                </div>
                <div>
                  <h4>Everything in Standard +</h4>
                  <div>
                    {premiumPlan?.features.map((text, index) => (
                      <div key={index}>
                        <BsCheckCircleFill size={16} />
                        <p>{text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </RadioGroup>
          <div className={styles["footer"]}>
            <div>
              <NewButton
                onClick={() => setChosenPlan(prev => ({ ...prev, done: true }))}
              >
                Continue
              </NewButton>
              <Link to={"/pricing"}>
                Go to Pricing Page <GrNext size={16} />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <PlanDetails
          chosenPlan={chosenPlan}
          goBack={() => setChosenPlan(prev => ({ ...prev, done: false }))}
          setClientSecret={val => setClientSecret(val)}
          setModalState={state => setModalState(state)}
        />
      )}
    </>
  );
};

export default Plan;
