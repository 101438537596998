import axios, { axiosPrivate } from "./axios";

class AuthAPI {
  /**
   * @param {{email: string, password: string} | {code: string}} data
   */
  static async login(data) {
    const response = await axios.post("/auth/login", {
      ...data
    });
    return response.data.data;
  }

  /**
   * @param {string} authType
   */
  static async getAuthUrl(authType) {
    const response = await axios.post("/auth/getAuthUrl", {
      authType
    });
    return response.data;
  }

  /**
   * @param {{email: string, password: string, name: string} | {code: string}} data
   */
  static async signup(data) {
    const response = await axios.post("/auth/signup", {
      ...data
    });
    return response.data;
  }

  /**
   * @param {string} refreshToken
   */
  static async logout(refreshToken) {
    const response = await axiosPrivate.patch("/auth/logout", { refreshToken });
    return response.data;
  }

  /**
   * @param {{newpassword: string, confirmpassword: string}} data
   */
  static async changePassword(data) {
    const response = await axiosPrivate.patch("/auth/changepassword", data);
    return response.data;
  }
}

export default AuthAPI;
