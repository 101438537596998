import React from "react";
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import styles from "./payment.module.scss";
import NewButton from "../../new-button";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  async function handleStripeSubmit(e) {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    console.log("stripe", stripe);
    const data = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
        expand: ["payment_method"]
        // expand: ["payment_intent"]
      }
    });
    console.log(data, "stripe data");

    if (
      data.error.type === "card_error"
      // data.error.type === "validation_error"
    ) {
      console.log(data.error.message);
    } else {
      console.log("An unexpected error occured.");
    }
  }

  // const currentUser = JSON.parse(localStorage.getItem("userData"));

  return (
    <form
      id={styles["stripe-checkout-form"]}
      onSubmit={handleStripeSubmit}
      style={{
        overflowY: "auto"
      }}
    >
      <LinkAuthenticationElement
        id={styles["link-authentication-element"]}
        options={{
          defaultValues: {
            email: "qwer@gmail.com"
          }
        }}
      />
      <PaymentElement id={styles["payment-element"]} />
      <NewButton type={"submit"}>Submit</NewButton>
    </form>
  );
};

export default CheckoutForm;
