import { useQuery } from "@tanstack/react-query";
import UserAPI from "../api/user";

export function useLoggedInUser() {
  return useQuery({
    queryKey: ["_currentUser"],
    queryFn: UserAPI.getUser
    // staleTime: Infinity
  });
}
