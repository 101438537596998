import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import styles from "./dropdown.module.scss";
import Logout from "../assets/logout.svg";
import Profile from "../assets/profile-circle.svg";
import CaretUp from "../../../assets/svgs/caret-up.svg";
import DashboardIcon from "../assets/dashboard-icon.svg";
import CaretDown from "../../../assets/svgs/caret-down.svg";
import CurrentUserAvatar from "../../current-user-avatar";
import { useLogout } from "../../../mutations/auth/logout";

function DropDown() {
  const [isOpen, setIsOpen] = useState(false);

  const logout = useLogout();

  window.addEventListener("click", () => {
    setIsOpen(false);
  });

  const handleLogout = async e => {
    e.preventDefault();
    const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
    await logout.mutateAsync(refreshToken);
  };

  return (
    <div>
      <div
        className={styles["dropdown-container"]}
        onClick={e => e.stopPropagation()}
      >
        <button
          className={styles["dropdown-container__items"]}
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3>My Account</h3>
          <div></div>
        </button>
        <div className={styles["caret"]} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <img src={CaretUp} alt="caret-down" id={styles["caret-down"]} />
          ) : (
            <img src={CaretDown} alt="caret-down" id={styles["caret-down"]} />
          )}
        </div>
        <Link to="/profile">
          <CurrentUserAvatar />
        </Link>
      </div>
      {isOpen && (
        <div className={styles["drop"]} onClick={() => setIsOpen(false)}>
          <Link to="/dashboard" className={styles["drop__item"]}>
            <span>
              <img src={DashboardIcon} alt="logout-icon" />
            </span>
            <span>Dashboard</span>
          </Link>
          <Link to="/profile" className={styles["drop__item"]}>
            <span>
              <img src={Profile} alt="logout-icon" />
            </span>
            <span>View Profile</span>{" "}
          </Link>
          <Link to="/settings/account" className={styles["drop__item"]}>
            <span>
              <FiSettings color="#121212" />
            </span>
            <span>Settings</span>{" "}
          </Link>
          <span className={styles["drop__item"]} onClick={handleLogout}>
            <span>
              <img src={Logout} alt="logout-icon" />
            </span>
            <span id={styles["logout"]}>Log out</span>
          </span>
        </div>
      )}
    </div>
  );
}

export default DropDown;
