const defaulturl = "https://fonts.googleapis.com/css2?";

export const backendData = {
  fontUrl: `${defaulturl}family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap`,

  backgroundImage:
    "https://certifier-production-amplify.s3.eu-west-1.amazonaws.com/public/565302e3-5bff-4e5c-8b76-a7f0246fcb4a%2Fbackgrounds%2F13-11-20-2%3A20%3A05_Non-Vector%20(11).png",
  elements: [
    {
      name: "logo",
      url: "http://localhost:3000/static/media/stamp.08b90a8137adcccb8fa275ab9ebdfaff.png",
      top: 15.383,
      left: 13.3,
      isLogo: true
    },
    {
      //? NOTE: we might not need this
      name: "stamp",
      url: "http://localhost:3000/static/media/stamp.08b90a8137adcccb8fa275ab9ebdfaff.svg",
      top: 15.383,
      left: 13.3,
      width: 15.383,
      height: 20
    }
  ],
  texts: [
    {
      name: "title",
      top: 17.351,
      left: 8.35,
      fontSize: 3,
      fontFamily: "Poppins",
      fontWeight: 700,
      color: "blue",
      text: "Certificate",
      isAttribute: true,
      attribute: "title"
    },
    {
      name: "titleII",
      top: 40.278,
      left: 8.35,
      fontSize: 0.9375,
      fontFamily: "Poppins",
      fontWeight: 300,
      color: "blue",
      text: "This certificate is awarded to:",
      isAttribute: true,
      attribute: "titleII"
    },
    {
      name: "name",
      top: 44.44,
      left: 8.35,
      fontSize: 3.125,
      fontFamily: "Great vibes",
      fontWeight: 400,
      color: "blue",
      isAttribute: true,
      attribute: "name"
    },
    {
      name: "description",
      width: 40,
      top: 63.88,
      left: 8.35,
      fontSize: 0.8125,
      fontFamily: "Poppins",
      fontWeight: 300,
      color: "blue",
      text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      isAttribute: true,
      attribute: "description"
    },
    {
      name: "issuedBy",
      top: 83.33,
      left: 8.35,
      fontSize: 0.9375,
      fontFamily: "Poppins",
      fontWeight: 700,
      color: "blue",
      text: "Date:",
      isAttribute: true,
      attribute: "issuedBy"
    },
    {
      name: "date",
      top: 87.5,
      left: 8.35,
      fontSize: 0.875,
      fontFamily: "Poppins",
      fontWeight: 300,
      color: "blue",
      isAttribute: true,
      attribute: "date"
    }
  ]
};

export const frontendData = {
  backgroundImage:
    "https://certifier-production-amplify.s3.eu-west-1.amazonaws.com/public/565302e3-5bff-4e5c-8b76-a7f0246fcb4a%2Fbackgrounds%2F13-11-20-2%3A20%3A05_Non-Vector%20(11).png",
  logo: {
    url: "http://localhost:3000/static/media/stamp.08b90a8137adcccb8fa275ab9ebdfaff.png",
    top: 7.98, //? REAL
    left: 50 //? REAL
  },

  //? NOTE this is can be a png if needed
  stamp: {
    url: "http://localhost:3000/static/media/stamp.08b90a8137adcccb8fa275ab9ebdfaff.svg",
    top: 15.383,
    left: 13.3,
    width: 15.383
  },

  //? NOTE this is the text data I will need to build the certificate
  certificateContent: {
    title: {
      text: "title text",
      top: 23, //? REAL
      left: 50, //? REAL
      fontSize: 3,
      fontFamily: "Poppins",
      fontWeight: 600, //? REAL
      fontUrl:
        "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap"
    },
    name: {
      text: "name text",
      top: 40, //? REAL
      left: 50, //? REAL
      fontSize: 3,
      fontFamily: "Poppins",
      fontUrl:
        "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap",
      fontWeight: 400 //? REAL
    },
    description: {
      text: "description text",
      top: 53, //? REAL
      left: 50, //? REAL
      fontSize: 3,
      fontFamily: "Poppins",
      fontUrl:
        "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap",
      fontWeight: 400 //? REAL
    },
    issuedBy: {
      text: "issuedBy text",
      top: 75, //? REAL
      left: 9.2, //? REAL
      fontSize: 3,
      fontFamily: "Poppins",
      fontUrl:
        "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap",
      fontWeight: 500 //? REAL
    },
    issuedDate: {
      text: "issuedDate text",
      top: 75.6, //? REAL
      left: 69.2, //? REAL
      fontSize: 3,
      fontFamily: "Poppins",
      fontUrl:
        "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@300;400;700&family=Rubik+Bubbles&display=swap",
      fontWeight: 500 //? REAL
    }
  }
};

const sideBarData = [
  {
    id: 1,
    templateURL: "http://",
    backgroundImage: "httmkfk",
    textPostions: {
      logo: {
        top: 10,
        right: 40.89
      },
      name: {
        top: 10,
        right: 40.89
      },
      title: {
        top: 10,
        right: 40.89
      },
      description: {
        top: 10,
        right: 40.89
      }
    }
  },
  {
    id: 2,
    templateURL: "http://",
    backgroundImage: "httmkfk",
    textPostions: {
      logo: {
        top: 10,
        right: 40.89
      },
      name: {
        top: 10,
        right: 40.89
      },
      title: {
        top: 10,
        right: 40.89
      },
      description: {
        top: 10,
        right: 40.89
      }
    }
  },
  {
    id: 2,
    templateURL: "http://",
    backgroundImage: "httmkfk",
    textPostions: {
      logo: {
        top: 10,
        right: 40.89
      },
      name: {
        top: 10,
        right: 40.89
      },
      title: {
        top: 10,
        right: 40.89
      },
      description: {
        top: 10,
        right: 40.89
      }
    }
  }
];
