import React from "react";
import Confirmed from "../../assets/images/illustrations/confirmed.png";
import { NewButton } from "../../Component";
import styles from "./payment-success.module.scss";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <main id={styles["payment-success"]}>
      <figure className={styles["illustration-wrapper"]}>
        <img src={Confirmed} alt="payment confirmed illustration" />
      </figure>
      <div className={styles["text-wrapper"]}>
        <h1>Payment successful</h1>
        <p>You have successfully subscribed for a plan</p>
      </div>
      <div className={styles["btn-wrapper"]}>
        <NewButton
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Go to dashboard
        </NewButton>
      </div>
    </main>
  );
};

export default PaymentSuccess;
