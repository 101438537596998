import { lazy } from "react";

export * from "./ResetPassword";
export const Privacy = lazy(() =>
  import("./PrivacyPolicy").then(module => {
    return { default: module.Privacy };
  })
);
export const FAQ = lazy(() => import("./FAQ"));
export const Team = lazy(() => import("./Team"));
export const Terms = lazy(() => import("./Terms"));
export const Error = lazy(() => import("./Error"));
export const Career = lazy(() => import("./Career"));
export const AboutUs = lazy(() => import("./AboutUs"));
// export const Preview = lazy(() => import("./Preview"));
export const Pricing = lazy(() => import("./Pricing"));
// export const EditBulk = lazy(() => import("./EditBulk"));
export const Checkout = lazy(() => import("./Checkout"));
// export const Dashboard = lazy(() => import("./Dashboard"));
export const Templates = lazy(() => import("./Templates"));
export const ContactUs = lazy(() => import("./contactUs"));
export const ComingSoon = lazy(() => import("./ComingSoon"));
// export const BulkStep = lazy(() => import("./Home/BulkStep"));
// export const ProfilePage = lazy(() => import("./ProfilePage"));
export const NewProfilePage = lazy(() => import("./new-profile-page"));
// export const BulkPreview = lazy(() => import("./BulkPreview"));
export const Onboarding = lazy(() => import("./Onboarding"));
// export const Layout = lazy(() => import("../Component/Layout"));
// export const Generate = lazy(() => import("./Dashboard/Generate"));
export const Choice = lazy(() => import("./Choice(single or bulk)"));
// export const GroupCollection = lazy(() => import("./GroupCollection"));
// export const GroupCollectionFiles = lazy(() =>
//   import("./GroupCollection/CollectionFiles")
// );
// export const GroupCollectionFolders = lazy(() =>
//   import("./GroupCollection/CollectionFolders")
// );
export const CertificateCreatorOrReceipient = lazy(() =>
  import("./NewDashboardContainer/CertificateCreatorOrReceipient")
);
export const PreviewCertificate = lazy(() =>
  import("./NewDashboardContainer/PreviewCertificate")
);
export const PreviewRecipientCertificate = lazy(() =>
  import("./NewDashboardContainer/PreviewRecipientCertificate")
);
export const CreateCertificate = lazy(() =>
  import("./NewDashboardContainer/CreateCertificate")
);
export const UnauthPreviewCertificate = lazy(() =>
  import("./UnauthPreviewCertificate")
);

export const Home = lazy(() => import("./Home"));
export const Settings = lazy(() => import("./settings"));
export const Account = lazy(() => import("./settings/account"));
export const ChangePassword = lazy(() => import("./settings/change-password"));
