import React, { createContext, useState } from "react";

export const PreviewCertificateContext = createContext();

const PreviewCertificateProvider = ({ children }) => {
  const [displayAddRecipientRow, setDisplayAddRecipientRow] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState([]);
  const [showTemplateShadow, setShowTemplateShadow] = useState(false);
  const [previewRecipientCertificate, setPreviewRecipientCertificate] =
    useState(null);
  const [displayUploadCsvModal, setDisplayUploadCsvModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [searchCertificateValue, setSearchCertificateValue] = useState("");

  const showTemplateShadowHandler = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth"
    });
    setShowTemplateShadow(true);
    setTimeout(() => {
      setShowTemplateShadow(false);
    }, 2000);
  };

  return (
    <PreviewCertificateContext.Provider
      value={{
        displayAddRecipientRow,
        setDisplayAddRecipientRow,
        selectedRecipient,
        setSelectedRecipient,
        showTemplateShadow,
        setShowTemplateShadow,
        previewRecipientCertificate,
        setPreviewRecipientCertificate,
        showTemplateShadowHandler,
        displayUploadCsvModal,
        setDisplayUploadCsvModal,
        displayDeleteModal,
        setDisplayDeleteModal,
        pageNumber,
        setPageNumber,
        searchCertificateValue,
        setSearchCertificateValue
      }}
    >
      {children}
    </PreviewCertificateContext.Provider>
  );
};

export default PreviewCertificateProvider;
