import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FaBars, FaTimes } from "react-icons/fa";
import DropDown from "./Dropdown";
import "./navbar.style.scss";
import Logo from "../Logo";
import NewButton from "../new-button";

function Navbar({ links }) {
  const [switchFa, setSwitchFa] = useState(false);
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData(["_currentUser"]);

  const handleToggle = () => {
    setSwitchFa(!switchFa);
  };

  return (
    <div id="nav">
      <div className="navbar">
        <div className="nav-header-container">
          <div className="nav-header nav-container">
            <Logo />

            <div className="nav-click">
              {currentUser ? (
                <div className="dropdown-tab">{/* <DropDown/> */}</div>
              ) : (
                <Link to="/login">
                  <NewButton className={"started-btn"}>Get Started</NewButton>
                </Link>
              )}
              {switchFa === true ? (
                <FaTimes className="times" onClick={handleToggle} />
              ) : (
                <FaBars className="bars" onClick={handleToggle} />
              )}
            </div>
          </div>
        </div>
        <div className="nav-links">
          <div
            className={`links-container nav-container ${
              switchFa && "show-links"
            }`}
            // ref={navRef}
          >
            <div className="links">
              {links.map(link => {
                const { id, url, text } = link;
                return (
                  <p key={id}>
                    <NavLink to={url} className="link" onClick={handleToggle}>
                      {text}
                    </NavLink>
                  </p>
                );
              })}
            </div>

            {currentUser ? (
              <div className="dropdown-wrapper">
                <DropDown />
              </div>
            ) : (
              <div className="button-container">
                <NavLink to="/login">
                  <NewButton onClick={handleToggle}>Get Started</NewButton>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
