import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import "./new-container.style.scss";
import DashboardBackBtnIcon from "../../assets/svgs/dashboard-back-btn-icon.svg";
import { DashboardProvider } from "../../contexts/DashboardProvider";

const NewContainer = () => {
  const links = [
    {
      id: 1,
      url: "/dashboard",
      text: "Dashboard"
    },
    // {
    //   id: 2,
    //   url: "/browse-template",
    //   text: "Browse Templates"
    // },
    {
      id: 3,
      url: "/pricing",
      text: "pricing"
    }
  ];

  return (
    <div style={{ backgroundColor: "white" }} id="new-dashboard-container">
      <Navbar links={links} />
      <DashboardProvider>
        <Outlet />
      </DashboardProvider>
    </div>
  );
};

export const BackBtn = ({ to = -1 }) => {
  const navigate = useNavigate();
  function backBtnHandler() {
    navigate(to);
  }
  return (
    <div
      id="dashboard-back-btn-icon"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        cursor: "pointer"
      }}
      onClick={backBtnHandler}
    >
      <img src={DashboardBackBtnIcon} alt="dashboard-back-btn-icon" />
      <p style={{ fontWeight: "500", color: "#19A68E" }}>Back</p>
    </div>
  );
};

export default NewContainer;
