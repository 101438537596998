import { axiosPrivate } from "./axios";

class UserAPI {
  static async getUser() {
    const response = await axiosPrivate.get("/user");
    return response.data.data;
  }

  static async updateProfile() {
    const response = await axiosPrivate.patch("/user/profile");
    return response.data;
  }
}

export default UserAPI;
