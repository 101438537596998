import { isAxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import AuthAPI from "../../api/auth";
import Toast from "../../Component/toast-alert";

export function useSignup() {
  return useMutation({
    mutationFn: AuthAPI.signup,
    onSuccess: () => {
      Toast.fire({
        icon: "success",
        title: "Signed up successfully, please login"
      });
    },
    onError: error => {
      if (isAxiosError(error)) {
        if (error.response.status === 400) {
          Toast.fire({
            icon: "error",
            title: "all fields are required"
          });
        } else if (error.response.status === 401) {
          Toast.fire({
            icon: "error",
            title: "Email already exists, login"
          });
        } else if (error.response.status === 500) {
          Toast.fire({
            icon: "error",
            title: "Internal server Error"
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Something went wrong"
          });
        }
      }
    }
  });
}
