import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import { DashboardProvider } from "../../contexts/DashboardProvider";
import "./dashboard.style.scss";
import CurrentPlanModal from "../../Component/current-plan-modal";
import UpgradeAccountModal from "../../Component/upgrade-account-modal";

const Dashboard = () => {
  const links = [
    {
      id: 1,
      url: "/dashboard",
      text: "Dashboard"
    },
    // {
    //   id: 2,
    //   url: "/browse-template",
    //   text: "Browse Templates"
    // },
    {
      id: 3,
      url: "/pricing",
      text: "pricing"
    }
  ];

  return (
    <div id="dashboard-layout">
      <Navbar links={links} />
      <DashboardProvider>
        <Outlet />
        <CurrentPlanModal />
        <UpgradeAccountModal />
      </DashboardProvider>
    </div>
  );
};

export default Dashboard;
