import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/navbarIcon.png";
import "./logo.style.scss";

const Logo = () => {
  return (
    <Link to={"/"} className="nav-logo">
      <h2>
        Cert<span>go</span>
      </h2>
      <img src={logo} alt="Certgo bulb" />
    </Link>
  );
};

export default Logo;
