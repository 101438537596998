import React from "react";
import { LoadingButton } from "@mui/lab";
import styles from "./new-button.module.scss";
import Loader from "../loader";

const NewButton = ({
  loading,
  variant = "contained",
  id,
  className,
  disabled,
  color = "primary",
  ...props
}) => {
  return (
    <LoadingButton
      variant={variant}
      id={`${id}`}
      className={`${styles["button"]} ${className}`}
      sx={{
        "&.Mui-disabled": {
          backgroundColor:
            variant === "outlined"
              ? color === "error"
                ? "rgb(240 68 56 / 20%)"
                : color === "primary"
                ? "rgb(25 166 142 / 20%)"
                : color === "info" && "rgb(94 94 94 / 20%)"
              : variant === "contained" &&
                (color === "error"
                  ? "rgb(240 68 56)"
                  : color === "primary"
                  ? "rgb(25 166 142)"
                  : color === "info" && "rgb(94 94 94)")
        }
      }}
      color={color}
      disableElevation
      disabled={disabled}
      loading={loading}
      loadingIndicator={""}
      {...props}
    >
      {loading ? (
        <Loader color={"white"} width={30} height={30} />
      ) : (
        <>{props.children}</>
      )}
    </LoadingButton>
  );
};

export default NewButton;
