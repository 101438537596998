import React, { useState } from "react";
import { Radio, RadioGroup } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import moment from "moment/moment";
import { BiArrowBack } from "react-icons/bi";
import { axiosPrivate } from "../../../api/axios";
import NewButton from "../../new-button";
import styles from "./plan.module.scss";
import PlanData from "../../../utils/data/plan.json";

//! Refactor this component

const PlanDetails = ({
  chosenPlan,
  setClientSecret,
  setModalState,
  goBack
}) => {
  const standardPlan = PlanData.find(item => item.plan === "standard");
  const premiumPlan = PlanData.find(item => item.plan === "premium");

  const [paymentSeries, setPaymentSeries] = useState({
    value: "monthly",
    price:
      chosenPlan.value === "premium"
        ? premiumPlan?.monthly?.price
        : standardPlan?.monthly?.price,
    priceId:
      chosenPlan.value === "premium"
        ? process.env.REACT_APP_PREMIUM_MONTH_PRICE_ID
        : process.env.REACT_APP_BASIC_MONTH_PRICE_ID
  }); // 'monthly' | 'yearly'

  const subscribeToPlan = useMutation({
    mutationFn: async () =>
      await axiosPrivate.post("/payments/create-subscription", {
        priceId: paymentSeries.priceId
      }),
    onSuccess: response => {
      setClientSecret(response.data.clientSecret);
    }
  });

  return (
    <div id={styles["plan-details"]}>
      <div className={styles["title"]} onClick={goBack}>
        <BiArrowBack color="#5e5e5e" size={24} />
        <h4>{`${chosenPlan.value} Plan`}</h4>
      </div>
      <RadioGroup className={styles["radio-group"]} value={paymentSeries.value}>
        <div
          className={styles["radio-wrapper"]}
          onClick={() => {
            setPaymentSeries({
              value: "monthly",
              price: chosenPlan.value === "premium" ? 20 : 10,
              priceId:
                chosenPlan.value === "premium"
                  ? process.env.REACT_APP_PREMIUM_MONTH_PRICE_ID
                  : process.env.REACT_APP_BASIC_MONTH_PRICE_ID
            });
          }}
        >
          <Radio value={"monthly"} className={styles["radio"]} />
          <div>
            <h5>Monthly</h5>
            <p>
              {`${
                chosenPlan.value === "premium"
                  ? `$${premiumPlan?.monthly?.price}`
                  : `$${standardPlan?.monthly?.price}`
              }`}{" "}
            </p>
          </div>
        </div>
        <div
          className={styles["radio-wrapper"]}
          onClick={() => {
            setPaymentSeries({
              value: "yearly",
              price: chosenPlan.value === "premium" ? 200 : 100,
              priceId:
                chosenPlan.value === "premium"
                  ? process.env.REACT_APP_PREMIUM_YEAR_PRICE_ID
                  : process.env.REACT_APP_BASIC_YEAR_PRICE_ID
            });
          }}
        >
          <Radio value={"yearly"} className={styles["radio"]} />
          <div>
            <h5>Yearly</h5>
            <p>
              {`$${
                chosenPlan.value === "premium"
                  ? premiumPlan?.yearly?.price
                  : standardPlan?.yearly?.price
              }`}{" "}
              <span>
                {`${
                  chosenPlan.value === "premium"
                    ? premiumPlan?.yearly?.price &&
                      `($${(premiumPlan.yearly.price / 12).toFixed(2)}/month)`
                    : standardPlan?.yearly?.price &&
                      `($${(standardPlan.yearly.price / 12).toFixed(2)}/month)`
                }`}
              </span>
            </p>
          </div>
        </div>
      </RadioGroup>
      <div className={styles["timeline"]}>
        <div>
          <p>Billing Starts</p>
          <span>{moment(new Date()).format("MMM D, YYYY")}</span>
        </div>
        <div>
          <p>Next Renewal</p>
          <span>
            {moment(new Date()).add(3, "months").format("MMM D, YYYY")}
          </span>
        </div>
      </div>
      <div className={styles["total"]}>
        <p>Total Bill</p>
        <span>${paymentSeries.price}</span>
      </div>
      <p className={styles["foot-note"]}>
        {`Billing starts on ${moment(new Date()).format("MMM D, YYYY")} and will
        renew automatically every month. Payments won't be refunded for partial
        billing periods. Cancel anytime in Certgo account settings.`}
      </p>
      <div className={styles["button-container"]}>
        <NewButton
          onClick={async () => {
            const data = await subscribeToPlan.mutateAsync();
            if (data) {
              setModalState("payment");
            }
          }}
          loading={subscribeToPlan.isLoading}
        >
          Checkout
        </NewButton>
      </div>
    </div>
  );
};

export default PlanDetails;
