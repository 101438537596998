import { axiosPrivate } from "./axios";

class CertificateTemplatesAPI {
  static async getCertificateTemplates() {
    const response = await axiosPrivate.get("/blueprints");

    return response.data.data;
  }

  static async createCertificateTemplate(data) {
    const response = await axiosPrivate.post("/templates", { ...data });

    return response.data.data;
  }

  static async updateCertificateTemplate(templateId, data) {
    const response = await axiosPrivate.patch(`/templates/${templateId}`, {
      ...data
    });

    return response.data.data;
  }
}

export default CertificateTemplatesAPI;
