import React from "react";
import { createContext, useState, useRef, useEffect } from "react";
import { frontendData } from "../Component/NewContainer/Data";
import WebFont from "webfontloader";
import fontFamilies from "../utils/templateFontFamilies";
import defaultData from "../utils/defaultTemplateData";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [logo, setLogo] = useState(null); //? file object
  const [loading, setLoading] = useState(false);
  const [certId, setCertId] = useState(null);
  const [sending, setSending] = useState(null);
  const [checkShadow, setCheckShadow] = useState(false);

  const [data, setData] = useState({ ...defaultData });
  const [changeTempData, setChangeTempData] = useState(data);
  const [activeText, setActiveText] = useState({});
  const [editCertificate, setEditCertificate] = useState(defaultData);

  const [currentPlanModal, setCurrentPlanModal] = useState({
    display: false,
    data: null
  });
  const [displayUpgradePlanModal, setDisplayUpgradePlanModal] = useState(false);

  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: "John Doe",
      email: "johndoe@gmail.com",
      status: "sent"
    },
    {
      id: 2,
      name: "John Doe",
      email: "johndoe@gmail.com",
      status: "pending"
    },
    {
      id: 3,
      name: "John Doe",
      email: "johndoe@gmail.com",
      status: "sent"
    }
  ]);

  const [preview, setPreview] = useState(null);

  const [collectionId, setCollectionId] = useState(null);

  const [previewTemplateData, setPreviewTemplateData] = useState(null);

  const logoInput = useRef(null);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [...fontFamilies]
      }
    });
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        frontendData,
        activeText,
        setActiveText,
        tableData,
        setTableData,
        preview,
        setPreview,
        data,
        setData,
        logo,
        setLogo,
        setEditCertificate,
        editCertificate,
        loading,
        setLoading,
        logoInput,
        defaultData,
        previewTemplateData,
        setPreviewTemplateData,
        changeTempData,
        setChangeTempData,
        certId,
        setCertId,
        sending,
        setSending,
        checkShadow,
        setCheckShadow,
        collectionId,
        setCollectionId,
        currentPlanModal,
        setCurrentPlanModal,
        displayUpgradePlanModal,
        setDisplayUpgradePlanModal
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
