import { isAxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AuthAPI from "../../api/auth";
import Toast from "../../Component/toast-alert";
import { axiosPrivate } from "../../api/axios";

export function useLogin() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: AuthAPI.login,
    onSuccess: data => {
      Toast.fire({
        icon: "success",
        title: "Loggedin successfully"
      });
      axiosPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.token}`;
      localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
      queryClient.setQueryData(["_currentUser"], data);
    },
    onError: error => {
      if (isAxiosError(error)) {
        if (error.response.status === 404) {
          Toast.fire({
            icon: "error",
            title: "A user for this email could not be found"
          });
        } else if (error.response.status === 401) {
          Toast.fire({
            icon: "error",
            title: "Invalid password, please try again"
          });
        } else if (error.response.status === 500) {
          Toast.fire({
            icon: "error",
            title: "Internal server Error"
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Something went wrong"
          });
        }
      }
    }
  });
}
