import { isAxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../../api/auth";
import Toast from "../../Component/toast-alert";

export function useLogout() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: AuthAPI.logout,
    onSuccess: () => {
      Toast.fire({
        icon: "success",
        title: "You have been successfully logged out"
      });
      localStorage.clear();
      navigate("/login");
    },
    onError: error => {
      if (isAxiosError(error)) {
        if (error.response.status === 400) {
          Toast.fire({
            icon: "error",
            title: "Error logging out"
          });
        }
      }
    }
  });
}
