import { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

import {
  AboutUs,
  // BulkStep,
  // BulkPreview,
  Career,
  Choice,
  ComingSoon,
  ContactUs,
  // EditBulk,
  Error,
  FAQ,
  // Layout,
  Pricing,
  Team,
  Templates,
  Terms,
  // ProfilePage,
  ChangePassword,
  ForgotPassword,
  PasswordLinkSent,
  ResetPassword,
  PasswordChangeSuccessfully,
  Home,
  Privacy,
  Checkout,
  // Generate,
  CertificateCreatorOrReceipient,
  PreviewCertificate,
  PreviewRecipientCertificate,
  CreateCertificate,
  UnauthPreviewCertificate,
  Onboarding,
  NewProfilePage,
  Settings,
  Account
} from "./pages";
import "./Style/App.scss";
// import { NewContainer } from "./Component";
import "react-loading-skeleton/dist/skeleton.css";
import CertificateProvider from "./contexts/CertificateProvider";
import PreviewCertificateProvider from "./contexts/PreviewCertificateProvider";
import Login from "./pages/login";
import Signup from "./pages/signup";
import PaymentSuccess from "./pages/payment-success";
import DashboardLayout from "./layouts/dashboard";
import ProtectedRoutes, { DenyAccessIfLoggedIn } from "./layouts/protected";
import AppLayout from "./layouts/app";
import { LazyLoader } from "./Component";

function App() {
  const [amount, setAmount] = useState(2.99);
  const [per, setPer] = useState("month");
  const [header, setHeader] = useState("More features customised for you");
  const [type, setType] = useState("Standard");
  const [text, setText] = useState("Everything in Basic +");
  const [subText, setSubText] = useState([]);

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname}`,
      title: `${window.location.pathname.slice(1)} Page`
    });
  }, []);

  function amountHandler(type, price, per, header, text, subText) {
    setType(type);
    setAmount(price);
    setPer(per);
    setHeader(header);
    setText(text);
    setSubText(subText);
  }
  return (
    <>
      <div className="App">
        <Routes>
          <Route element={<AppLayout />}>
            <Route exact path="/" index element={<Home />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            {/* <Route path="/generate/:generateId" element={<Generate />} /> */}
            <Route path="/templates" element={<Templates />} />
            <Route path="/career" element={<Career />} />
            <Route path="choice" element={<Choice />} />
            <Route path="/team" element={<Team />} />
            <Route path="/terms" element={<Terms />} />
            {/* <Route path="/preview" element={<Preview />} /> */}
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/FAQ" element={<FAQ />} />
            {/* <Route path="/bulk_step" element={<BulkStep />} /> */}
            {/* <Route path="/edit_bulk" element={<EditBulk />} /> */}
            {/* <Route path="/bulk_preview" element={<BulkPreview />} /> */}
            <Route
              path="/pricing"
              element={<Pricing amountHandler={amountHandler} />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/payment"
              element={
                <Checkout
                  type={type}
                  amount={amount}
                  per={per}
                  header={header}
                  text={text}
                  subText={subText}
                />
              }
            />

            <Route path="/privacy" element={<Privacy />} />
            {/* ResetPassword */}
            <Route path="/fff5" element={<PasswordChangeSuccessfully />} />
            <Route
              path="/changepassword/:userId/:token"
              element={<ResetPassword />}
            />
            <Route path="/fff3" element={<ChangePassword />} />
            <Route path="/fff2" element={<PasswordLinkSent />} />
            <Route path="/resetpassword" element={<ForgotPassword />} />
          </Route>
          <Route path="*" element={<Error />} />
          <Route element={<DenyAccessIfLoggedIn />}>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/:id" element={<UnauthPreviewCertificate />} />

          {/*  */}
          {/*  */}
          {/*  */}

          <Route element={<ProtectedRoutes />}>
            <Route exact path="/onboarding" element={<Onboarding />} />
            <Route element={<DashboardLayout />}>
              <Route
                exact
                path="/payment-success"
                element={<PaymentSuccess />}
              />
              <Route path="/profile" element={<NewProfilePage />} />
              <Route
                element={
                  <Suspense fallback={<LazyLoader />}>
                    <Settings />
                  </Suspense>
                }
              >
                <Route exact path="/settings/account" element={<Account />} />
                <Route
                  exact
                  path="/settings/change-password"
                  element={<ChangePassword />}
                />
              </Route>
              <Route
                path="/dashboard"
                element={<CertificateCreatorOrReceipient />}
              />
              {/* <Route
                path="/payment-canceled"
                element={<div>Payment was canceled</div>}
              /> */}
              <Route
                path="/preview/created/:id"
                element={
                  <PreviewCertificateProvider>
                    <PreviewCertificate />
                  </PreviewCertificateProvider>
                }
              />
              <Route
                path="/preview/receipient/:id"
                element={<PreviewRecipientCertificate />}
              />
              <Route
                path="/create-certificate"
                element={
                  <CertificateProvider>
                    <CreateCertificate />
                  </CertificateProvider>
                }
              />
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default Sentry.withProfiler(App);
