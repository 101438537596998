import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#19a68e"
    },
    info: {
      main: "#5E5E5E"
    },
    error: {
      main: "#F04438"
    }
  },
  components: {
    MuiTableContainer: {
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "10px",
        backgroundColor: "#F5F5F5"
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#555"
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableContainer-root": {
            borderRadius: "none"
          },
          "& .MuiTableCell-root": {
            padding: "1.2rem 2rem",
            fontSize: "1rem",
            textWrap: "nowrap",
            width: "fit-content"
          },
          "& .MuiTableRow-root": {
            borderSpacing: "0px 10px",
            "&:nth-of-type(even)": {
              backgroundColor: "#F7FFFC"
            }
          },
          "& .MuiTableHead-root": {
            backgroundColor: "white",
            "& .MuiTableCell-root": {
              backgroundColor: "#d8fff1",
              padding: "0.8rem 2rem",
              color: "#5e5e5e",
              textAlign: "start",
              fontWeight: "bold",
              alignSelf: "center"
            }
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiModal-backdrop": {
            backgroundColor: "#00000033",
            boxShadow: "0px 4px 30px 0px #00000026"
          },
          overflowY: "auto"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    }
  }
});

export default theme;
