import React, { useContext, useEffect, useState } from "react";
import { IconButton, Modal } from "@mui/material";
import { IoClose } from "react-icons/io5";
import styles from "./upgrade-account-modal.module.scss";
import DashboardContext from "../../contexts/DashboardProvider";
import Plan from "./plan";
import Payment from "./payment";

const UpgradeAccountModal = () => {
  const { displayUpgradePlanModal, setDisplayUpgradePlanModal } =
    useContext(DashboardContext);
  const [modalState, setModalState] = useState("plan"); // 'plan' | 'payment'
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    return () => {
      setClientSecret("");
      setModalState("plan");
    };
  }, []);

  return (
    <>
      <Modal
        id={styles["upgrade-account-modal"]}
        open={displayUpgradePlanModal}
        onClose={() => {
          setDisplayUpgradePlanModal(false);
          setClientSecret("");
          setModalState("plan");
        }}
        sx={{
          overflowY: "auto"
        }}
      >
        <div className={styles["wrapper"]}>
          <IconButton
            className={styles["close-button"]}
            onClick={() => {
              setDisplayUpgradePlanModal(false);
              setClientSecret("");
              setModalState("plan");
            }}
          >
            <IoClose className={styles["close-icon"]} />
          </IconButton>
          {modalState === "plan" ? (
            <Plan
              setClientSecret={val => setClientSecret(val)}
              setModalState={state => setModalState(state)}
            />
          ) : (
            modalState === "payment" && <Payment clientSecret={clientSecret} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default UpgradeAccountModal;
