import { BallTriangle } from "react-loader-spinner";

const Loader = ({ ...props }) => {
  return (
    <BallTriangle
      radius={5}
      width={100}
      height={100}
      visible={true}
      color="#4fa94d"
      ariaLabel="ball-triangle-loading"
      {...props}
    />
  );
};

export default Loader;
