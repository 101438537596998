import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { IconButton } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEyeOff } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import Logo from "../../assets/certgo-logo.svg";
import Banner from "../../assets/images/auth-banner.png";
import styles from "./signup.module.scss";
import { NewButton } from "../../Component";
import { useGetGoogleAuthUrl } from "../../mutations/auth/google";
import { useSignup } from "../../mutations/auth/signup";
import FormField from "../../Component/form-field";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  password: Yup.string().required(
    "Password is required and must be at least 8 characters"
  ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  acceptTerms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required")
});

const Signup = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const signup = useSignup();
  const getGoogleAuthUrl = useGetGoogleAuthUrl();

  useEffect(() => {
    (async () => {
      if (params.code) {
        const data = await signup.mutateAsync({
          code: params.code
        });

        if (data) {
          navigate("/dashboard");
        }
      }
    })();
  }, []);

  return (
    <div id={styles["signup"]}>
      <div className={styles["authContainer"]}>
        <div className={styles["left-pane"]}>
          <div className={styles["brand-logo"]}>
            <img src={Logo} alt="certgo-logo" />
          </div>
          <div className={styles["formDiv"]}>
            <div id={styles["heading"]}>Welcome to Certgo</div>
            <span id={styles["startGenerating"]}>
              Start generating certificates by creating a Certgo account
            </span>
            <NewButton
              variant="outlined"
              id={styles["google-signup-btn"]}
              onClick={async () => {
                const data = await getGoogleAuthUrl.mutateAsync("signup");
                if (data) {
                  window.location.href = data.urlAuth;
                }
              }}
              loading={getGoogleAuthUrl.isLoading}
            >
              <FcGoogle />
              Signup using Google
            </NewButton>
            <div id={styles["hrLine"]}>
              <span id={styles["or"]}>or</span>
            </div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
                acceptTerms: false
              }}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={async values => {
                const data = await signup.mutateAsync({
                  name: values.name,
                  email: values.email,
                  password: values.password
                });

                if (data) {
                  navigate("/login");
                }
              }}
            >
              {({ handleSubmit, values, setValues, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <FormField
                    id={styles["signup-email"]}
                    label="Full Name"
                    required
                    placeholder="Enter your full name"
                    name="name"
                    data-testid="signup-email"
                  />
                  <FormField
                    id={styles["signup-email"]}
                    label="Email"
                    required
                    placeholder="Enter your email address"
                    name="email"
                    type="email"
                    data-testid="signup-email"
                  />
                  <FormField
                    id={styles["signup-password"]}
                    label="Create Password"
                    required
                    placeholder="password"
                    name="password"
                    type={showPassword.password ? "text" : "password"}
                    data-testid="signup-password"
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            password: !prev.password
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.password ? (
                          <FiEyeOff size={20} />
                        ) : (
                          <MdOutlineRemoveRedEye size={20} />
                        )}
                      </IconButton>
                    }
                  />
                  <FormField
                    id={styles["signup-confirm-password"]}
                    label="Confirm Password"
                    required
                    placeholder="password"
                    name="confirmPassword"
                    type={showPassword.confirmPassword ? "text" : "password"}
                    data-testid="signup-confirm-password"
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            confirmPassword: !prev.confirmPassword
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.confirmPassword ? (
                          <FiEyeOff size={20} />
                        ) : (
                          <MdOutlineRemoveRedEye size={20} />
                        )}
                      </IconButton>
                    }
                  />
                  <div id={styles["checkTerms"]}>
                    <input
                      type="checkbox"
                      id={styles["checkbox"]}
                      value={values}
                      checked={values.acceptTerms}
                      onChange={e => {
                        setValues(prev => ({
                          ...prev,
                          acceptTerms: e.target.checked
                        }));
                      }}
                      name="acceptTerms"
                      required
                    />
                    <div className={styles["termsOfUse"]}>
                      By creating an account, I declare that I have read and
                      accepted Certgo’s{" "}
                      <Link to={"/terms"} id={styles["coloredTerms"]}>
                        {" "}
                        Terms of Use
                      </Link>{" "}
                      and
                      <Link to={"/privacy"} id={styles["coloredTerms"]}>
                        {" "}
                        Privacy Policy
                      </Link>
                    </div>
                  </div>
                  <NewButton
                    type="submit"
                    id={styles["create-account-btn"]}
                    disabled={!values.acceptTerms}
                    loading={isSubmitting}
                  >
                    Create account
                  </NewButton>
                </form>
              )}
            </Formik>
            <p className={styles["haveAccount"]}>
              Already have a Certgo account?{" "}
              <Link to="/login" id={styles["coloredTerms"]}>
                Login
              </Link>
            </p>
          </div>
        </div>
        <div className={styles["right-pane"]}>
          <img className={styles["cert_img"]} alt="" src={Banner} />
        </div>
      </div>
    </div>
  );
};

export default Signup;
