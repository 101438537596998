import { Avatar } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { BsFillPersonFill } from "react-icons/bs";
import styles from "./current-user-avatar.module.scss";

const CurrentUserAvatar = ({ ...props }) => {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData(["_currentUser"]);

  return (
    <div id={styles["current-user-avatar"]}>
      <Avatar
        color="primary"
        {...props}
        sx={{
          width: "50px",
          height: "50px",
          backgroundColor: "#19a68e",
          ...props.sx
        }}
      >
        {currentUser?.avatar ? (
          <img
            src={`data:image/png;base64,${currentUser?.avatar}`}
            alt="avatar"
          />
        ) : (
          <BsFillPersonFill size={20} />
        )}
      </Avatar>
    </div>
  );
};

export default CurrentUserAvatar;
