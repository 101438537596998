import React from "react";
import { OutlinedInput } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import styles from "./search-bar.module.scss";

const SearchBar = ({ ...props }) => {
  return (
    <div className={styles["search-bar"]}>
      {/* <input
        type="search"
        name="search-certificate"
        id="search-certificate"
        placeholder="Search Certificates"
        // {...props}
        // onFocus={handleFocus}
        // onBlur={handleBlur}
        // onChange={e => {
        //   setSearch(prev => ({ ...prev, search: e.target.value }));
        // if (e.target.value === "" && isFocused) {
        //   handleSearchRequest(e.target.value);
        //   return;
        // }
        // }}
        // onKeyDown={e => e.key === "Enter" && handleSearchRequest(search)}
        style={{
          backgroundColor: `${props.disabled && "white"}`,
          cursor: `${props.disabled ? "not-allowed" : ""}`
        }}
      /> */}
      <OutlinedInput
        {...props}
        startAdornment={<CiSearch size={24} fill="#19a68e" />}
        type="search"
      />
    </div>
  );
};

export default SearchBar;
