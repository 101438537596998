import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkout-form";
import styles from "./payment.module.scss";

const Payment = ({ clientSecret }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    setStripePromise(stripe);
  }, []);

  return (
    <div id={styles["stripe-form-wrapper"]}>
      <h4 className={styles["title"]}>Payment</h4>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default Payment;
