import React, { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { IconButton, Modal } from "@mui/material";
import styles from "./current-plan-modal.module.scss";
import DashboardContext from "../../contexts/DashboardProvider";
import { IoClose } from "react-icons/io5";
import Button from "../button";
import PlanData from "../../utils/data/plan.json";

const CurrentPlanModal = () => {
  const { currentPlanModal, setCurrentPlanModal, setDisplayUpgradePlanModal } =
    useContext(DashboardContext);

  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData(["_currentUser"]);

  function onClose() {
    setCurrentPlanModal(prev => ({ ...prev, display: false }));
  }

  const currentPlan = PlanData.find(
    item => item.plan === currentUser?.subscription?.toLowerCase()
  );

  return (
    <Modal
      id={styles["current-plan-modal"]}
      open={currentPlanModal?.display}
      onClose={onClose}
    >
      <div className={styles["wrapper"]}>
        <div className={styles["title"]}>
          <h4>Plan Instructions</h4>
        </div>
        <IconButton className={styles["close-button"]} onClick={onClose}>
          <IoClose className={styles["close-icon"]} />
        </IconButton>
        <p>
          Your usage has exceeded the limits of your current plan. Please
          consider upgrading your plan to access the necessary features.
        </p>
        <div>
          <h5>Current Plan</h5>
          <ul className={styles["plan-data"]}>
            {currentPlan?.features.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className={styles["btn-wrapper"]}>
          <Button
            onClick={() => {
              onClose();
              setDisplayUpgradePlanModal(true);
            }}
          >
            Upgrade Plan
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CurrentPlanModal;
