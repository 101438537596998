import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Toast from "../Component/toast-alert";
import LazyLoader from "../Component/LazyLoader";
import { useLoggedInUser } from "../queries/user";

export const DenyAccessIfLoggedIn = () => {
  const userAuth = localStorage.getItem("userData");

  if (userAuth) {
    Toast.fire({
      icon: "error",
      title: "You are already logged in"
    });
  }
  return userAuth ? <Navigate to="/dashboard" /> : <Outlet />;
};

const ProtectedRoutes = () => {
  const { data: currentUser, isLoading } = useLoggedInUser();

  if (isLoading) {
    return <LazyLoader />;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
